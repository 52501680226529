import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import ServiceMember from "./member/serviceMember"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const TeamListingsByService = () => {
  const { solisitorTeam } = useStaticQuery(TEAM_LIST_SOLICITORS_QUERY)

  return (
    <WorkListWrapper>
      {solisitorTeam.edges.map((item) => (
        <ServiceMember item={item.node} key={item.node.id} />
      ))}
    </WorkListWrapper>
  )
}
export default TeamListingsByService

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WorkListWrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 60px auto;
  align-content: baseline;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    margin: ${(props) => props.theme.gutter};
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const TEAM_LIST_SOLICITORS_QUERY = graphql`
  query TeamByServiceSol {
    solisitorTeam: allWpMember(
      filter: { acfTeam: { practiceType: { isSolicitor: { eq: true } } } }
    ) {
      edges {
        node {
          id
          title
          slug
          acfTeam {
            education
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            practiceType {
              isSolicitor
              isLitigation
              litigationServices
              solicitorServices
            }
          }
        }
      }
    }
  }
`
