import React from "react"
import styled from "styled-components"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const subHeader = ({ subHeading }) => (
  <SubHeaderBox>
    <div className="spacer-left" />
    <div className="mid-content">
      <div className="mid-left">
        <h3 dangerouslySetInnerHTML={{ __html: subHeading }} />
      </div>
    </div>
    <div className="mid-right" />
    <div className="spacer-right" />
  </SubHeaderBox>
)
export default subHeader

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const SubHeaderBox = styled.div`
  width: 100vw;
  position: absolute;
  margin-top: 20px;
  left: 0;
  display: grid;
  grid-template-columns: auto ${(props) => props.theme.maxWidth} auto;
  @media (max-width: 1440px) {
    grid-template-columns: auto;
  }
  .spacer-left {
    background: rgba(9, 51, 52, 0.64);
  }
  .mid-content {
    display: grid;
    grid-template-columns: 8fr 4fr;

    .mid-left {
      background: rgba(9, 51, 52, 0.64);
      padding: 20px 20px 20px 0px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      h3 {
        font-size: 24px;
        padding-left: 20px;
        font-weight: 500;
        line-height: 1.3;
        @media (max-width: 1440px) {
          margin-left: 20px;
        }
        @media (max-width: 800px) {
          font-size: 18px;
        }
      }
    }
    @media (max-width: 800px) {
      display: block;
      width: 100vw;
    }
  }
  .spacer-right {
    background: transparent;
  }
`
