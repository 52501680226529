import React from "react"
import styled from "styled-components"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Container = ({ bg, children }) => (
  <ContainerOuter bg={bg}>
    <InnerContainer>{children}</InnerContainer>
  </ContainerOuter>
)
export default Container

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const ContainerOuter = styled.div`
  background-color: ${(props) => (props.bg ? props.bg : "#fff")};
`
const InnerContainer = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  padding: 60px 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  @media (max-width: 500px) {
    padding: 50px 23px 50px 17px;
    grid-gap: 0px;
  }
`
