import React from "react"
import styled from "styled-components"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Line = (center) => (
  <div>
    <BorderLine center={center} />
  </div>
)
export default Line

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const BorderLine = styled.div`
  width: 160px;
  border-top: 10px solid ${(props) => props.theme.gold};
  margin: 0px 0px 30px 0px;
  @media (max-width: 500px) {
    margin-left: 20px;
    border-top: 5px solid ${(props) => props.theme.gold};
  }
`
