import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import posed from "react-pose"
import styled from "styled-components"
import SeoComponent from "../../../components/seoComponent"
import Layout from "../../layout"
import Container from "../../Container"
import Header from "../../header/header"
import Footer from "../../footer/footer"
import PageHeader from "../../pageHeader/pageHeader"
import TeamListingsSolicitors from "../../../components/team/teamBySolicitors"
import TeamListingsLitigation from "../../../components/team/teamByLitigation"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const SingleService = ({ pageContext: { service } }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let fadeIn = setTimeout(() => setIsVisible(true), 500)
    return () => {
      clearTimeout(fadeIn)
    }
  }, [])

  return (
    <Layout>
      <Header />
      <SeoComponent seo={service.seo} />
      <PageAnimationWrapper pose={isVisible ? "visible" : "hidden"}>
        <PageHeader
          heading={service.acfPageHeader.heading}
          subHeading={service.acfPageHeader.subHeading}
          bg={service.acfPageHeader.headerImage.sourceUrl}
          compact={service.acfPageHeader.compact}
          mobileHeight="154px"
        />
        <Container>
          <ServiceInner>
            <div
              dangerouslySetInnerHTML={{
                __html: service.content,
              }}
            />
            <p />
          </ServiceInner>
          <BlogAllLink>
            <Link to={`/${service.categories.nodes[0].slug}`}>
              Back to All {service.categories.nodes[0].slug} Services
            </Link>
          </BlogAllLink>
        </Container>
        {service.categories.nodes[0].slug === "litigation" && (
          <Container bg="#093334">
            <OurTeam>
              <h2>Our Litigators</h2>
              <LeftLine />
              <TeamListingsLitigation />
            </OurTeam>
          </Container>
        )}
        {service.categories.nodes[0].slug === "solicitors" && (
          <Container bg="#093334">
            <OurTeam>
              <h2>Our Solicitors</h2>
              <LeftLine />
              <TeamListingsSolicitors />
            </OurTeam>
          </Container>
        )}
      </PageAnimationWrapper>
      <Footer />
    </Layout>
  )
}
export default SingleService

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PageAnimationWrapper = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 100, opacity: 0 },
})

const ServiceInner = styled.div`
  h3 {
    margin-top: 10px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.green};
  }
`
const BlogAllLink = styled.div`
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.gold};
  padding: 60px 40px 0 40px;
  a {
    background-color: ${(props) => props.theme.green};
    padding: 12px 40px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    &:hover {
      background: ${(props) => props.theme.dark};
      color: ${(props) => props.theme.white};
      transform: scale(1.05);
    }
  }
`
const LeftLine = styled.div`
  background-color: ${(props) => props.theme.gold};
  height: 4px;
  width: 80px;
  margin: 20px 0;
`
const OurTeam = styled.div`
  h2 {
    color: #fff;
  }
`
