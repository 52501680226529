import React from "react"
import { Helmet } from "react-helmet"
import { createAbsoluteLink } from "../../util"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const SEO = ({
  seo,
  description,
  lang = "en",
  meta = [],
  keywords = [],
  title,
}) => {
  // Title
  const titleText =
    title ||
    (seo && seo.title.replace("&amp;", "&")) ||
    process.env.GATSBY_SITE_TITLE
  // Description
  const metaDescription =
    description || (seo && seo.metaDesc) || process.env.GATSBY_SITE_DESCRIPTION
  // Keywords
  seo && seo.focuskw
    ? keywords.push(seo.focuskw)
    : keywords.push(process.env.GATSBY_SITE_TITLE)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titleText}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `title`,
          content: (seo && seo.opengraphTitle) || titleText,
        },
        {
          property: `og:title`,
          content: (seo && seo.opengraphTitle) || titleText,
        },
        {
          property: `og:description`,
          content: (seo && seo.opengraphDescription) || metaDescription,
        },
        {
          property: `og:type`,
          content: (seo && seo.opengraphType) || `website`,
        },
        {
          property: `og:image`,
          content:
            seo &&
            seo.opengraphImage !== null &&
            seo.opengraphImage.mediaItemUrl,
        },
        {
          property: `og:url`,
          content: seo && createAbsoluteLink(seo.opengraphUrl),
        },
        {
          property: `og:site_name`,
          content: seo && seo.opengraphSiteName,
        },
        {
          property: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:title`,
          content: (seo && seo.twitterTitle) || title,
        },
        {
          property: `twitter:image`,
          content:
            seo && seo.twitterImage !== null && seo.opengraphImage.mediaItemUrl,
        },
        {
          property: `twitter:description`,
          content: (seo && seo.twitterDescription) || metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}
export default SEO
